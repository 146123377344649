<template>
  <div>
    <div class="content">
      <header-component></header-component>

      <menu-component></menu-component>

      <div class="main_content">
        <div class="about_us_header">
          About Us
        </div>
        <div class="about_us_main">
          <p>
            Jim Kellogg of Equine Regalia is no stranger to those in the Arabian horse world.
            Showing and training Arabians <br>since 1967, Jim is also known for award-winning Arabian costumes.
            With over 40 years experience, Jim has designed over 650 individualized costumes, which have resulted in
            numerous National/Regional and Class A Champions through out the U.S., Canada and South America. Although
            Jim has consistently produced national winning costumes since <br>1976, he is most proud of his
            accomplishments
            in 2000, where all 8 US National Champions and the Reserve National Champion of Canada were designed and
            made
            by Equine Regalia. No other single person has matched this feat.<br><br>
            Let Jim of Equine Regalia, give you the edge to become<br>
            <b><font size="5">THE NEXT CHAMPION OF THE RING.......</font></b><br><br>
            You and Your Horse (75%) + Equine Regalia Costume (25%) = 100%
          </p>
        </div>
      </div>

      <div class="clearfooter"></div>

      <footer-component></footer-component>

    </div>
  </div>
</template>

<script>
import headerComponent from "@/components/HeaderComponent";
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";

export default {
  name: "HomeView",
  components: {
    headerComponent,
    menuComponent,
    footerComponent
  }
}
</script>

<style scoped>
div.clearfooter {
  height: 6px;
  clear: both;
}

div.main_content {
  height: 590px;
}

div.about_us_header {
  color: #E2DCA4;
  text-align: center;
  font-size: 24px;
  padding-top: 10px;
}

div.about_us_main {
  position: relative;
  color: #E2DCA4;
  text-align: center;
}
</style>