<template>
  <div>
    <div class="content">
      <header-component></header-component>

      <menu-component></menu-component>

      <div class="main_content">
        <div class="exhibit_schedule_header">
          Visit Us At The Following Shows
        </div>
        <div class="exhibit_schedule_subheader">
          Youth & Mid-Summer Nationals, Oklahoma City, Oklahoma
        </div>
        <div class="exhibit_schedule_us_nationals_img">
          <img src="/images/Costume Booth Photo.jpg">
        </div>
        <div class="exhibit_schedule_scottsdale_img">
          <img src="/images/Costume Booth Photo 2A.jpg">
        </div>
        <div class="exhibit_schedule_lowersubheader">
          U.S. Nationals, Tulsa, Oklahoma
        </div>
      </div>

      <div class="clearfooter"></div>

      <footer-component></footer-component>

    </div>
  </div>
</template>

<script>
import headerComponent from "@/components/HeaderComponent";
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";

export default {
  name: "HomeView",
  components: {
    headerComponent,
    menuComponent,
    footerComponent
  }
}
</script>

<style scoped>
div.clearfooter {
  height: 6px;
  clear: both;
}

div.main_content {
  height: 590px;
}

div.exhibit_schedule_header {
  color:#E2DCA4;
  text-align:center;
  font-size:24px;
  padding-top:10px;
}

div.exhibit_schedule_subheader {
  color:#E2DCA4;
  text-align:center;
  font-size:18px;
  padding-top:50px;
}

div.exhibit_schedule_lowersubheader {
  color:#E2DCA4;
  text-align:center;
  font-size:18px;
  position: relative;
  top: -80px;
}

div.exhibit_schedule_us_nationals_text {
  position:relative;
  color:#E2DCA4;
  left:150px;
  top:30px;
  width:200px;
}

div.exhibit_schedule_us_nationals_img {
  position:relative;
  color:#E2DCA4;
  top:30px;
  left:90px;
  width:200px;
  height:150px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
}

div.exhibit_schedule_us_nationals_img img {
  height: 100%;
  width: 100%;
}

div.exhibit_schedule_scottsdale_text {
  position:relative;
  color:#E2DCA4;
  top:-135px;
  left:440px;
  width:204px;
}

div.exhibit_schedule_scottsdale_img {
  position:relative;
  color:#E2DCA4;
  width:200px;
  height:150px;
  top:-120px;
  left:441px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
}

div.exhibit_schedule_scottsdale_img img {
  height: 100%;
  width: 100%;
}

</style>