<template>
  <div class="menu">
    <div class="buttonDefault">
      <p><a href="/">Home</a></p>
    </div>
    <div class="buttonDefault">
      <p><a href="/about">About Us</a></p>
    </div>
    <div class="buttonDefault">
      <p><a href="/winning_combinations">Winning Combinations</a></p>
    </div>
    <div class="buttonDefault">
      <p><a href="/costumes">Costumes</a></p>
    </div>
    <div class="buttonDefault">
      <p><a href="/come_see_us">Come See Us</a></p>
    </div>
    <div class="buttonDefault">
      <p><a href="/contact_us">Contact Us</a></p>
    </div>
    <p class="payment_info_menu">
      We Accept<br>Mastercard, Visa,<br>or Checks
    </p>
    <hr>
    <p class="prices_starting_info">
      Prices<br>Starting At<br>$2,500
    </p>

  </div>
</template>

<script>
export default {
  name: "MenuComponent"
}
</script>

<style scoped>
div.menu {
  position: relative;
  color: #E2DCA4;
  width: 180px;
  padding-bottom: 3px;
  margin-top: 5px;
  border-style: solid;
  border-color: #E2DCA4;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
  z-index: 3;
}

.buttonDefault {
  background-color: #E2DCA4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-style: solid;
  border-color: #E2DCA4;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
  width: 169px;
  left: 4px;
  position: relative;
  margin-top: 4px;
}

.buttonDefault:hover {
  background-color: #a6a17c;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-style: solid;
  border-color: #a6a17c;
}

.buttonDefault a {
  height: 45px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  color: black;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

p.payment_info_menu {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 17px;
}

p.prices_starting_info {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 17px;
}
</style>