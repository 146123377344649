<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
body {
  background-color:#000000;
  margin:0;
  padding:0;
  height:100%;
}

hr {
  border: 0px;
  color: #E2DCA4;
  background-color: #E2DCA4;
  width: 80%;
  height: 5px;
}

a {
  color: #FF0000;
}

div.content {
  position: relative;
  width: 960px;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
}

div.main_content {
  position: relative;
  width: 768px;
  margin-left: 192px;
  margin-top: -594px;
  border-style: solid;
  border-color: #E2DCA4;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
}
</style>
