import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/HomeView.vue';
import AboutUsView from "@/views/AboutUsView";
import WinningCombinationsView from "@/views/WinningCombinationsView";
import CostumeView from "@/views/CostumeView";
import ContactUsView from "@/views/ContactUsView";
import ComeSeeUsView from "@/views/ComeSeeUsView";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: Home,
    },
    {
        path: '/about',
        name: 'AboutUsView',
        component: AboutUsView,
    },
    {
        path: '/winning_combinations',
        name: 'WinningCombinationsView',
        component: WinningCombinationsView,
    },
    {
        path: '/costumes',
        name: 'CostumeView',
        component: CostumeView,
    },
    {
        path: '/come_see_us',
        name: 'ComeSeeUsView',
        component: ComeSeeUsView,
    },
    {
        path: '/contact_us',
        name: 'ContactUsView',
        component: ContactUsView,
    },
];

const index = createRouter({
    history: createWebHistory(),
    routes,
});

export default index;