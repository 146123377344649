<template>
  <div>
    <div class="content">
      <header-component></header-component>

      <menu-component></menu-component>

      <div class="main_content">
        <div class="contact_us_header">
          Contact Us
        </div>
        <div class="contact_us_main">
          <p>
            Equine Regalia/Jim Kellogg<br>
            3313 Highland Ave., Benton Harbor, MI 49022<br>

            Phone: (269) 325-7901<br>
            Email: <a href="mailto:akkhir@parrett.net">akkhir@parrett.net</a><br><br>
            Follow us on Facebook <a target="_blank" href="https://www.facebook.com/jim.kelloggequineregalia"><img class="fblogo" src="/images/Facebook-logo.png"> <span>https://www.facebook.com/jim.kelloggequineregalia</span></a>
          </p>
        </div>
      </div>

      <div class="clearfooter"></div>

      <footer-component></footer-component>

    </div>
  </div>
</template>

<script>
import headerComponent from "@/components/HeaderComponent";
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";

export default {
  name: "HomeView",
  components: {
    headerComponent,
    menuComponent,
    footerComponent
  }
}
</script>

<style scoped>
div.clearfooter {
  height: 6px;
  clear: both;
}

div.main_content {
  height: 590px;
}

div.contact_us_header {
  color: #E2DCA4;
  text-align: center;
  font-size: 24px;
  padding-top: 10px;
}

div.contact_us_main {
  position: relative;
  color: #E2DCA4;
  text-align: center;
}

img.fblogo {
  position: relative;
  top: 7px;
  left: 10px;
  height: 28px;
  padding-right: 20px;
}
</style>