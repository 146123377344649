<template>
  <div class="footer">
    <p class="footer">Send mail to <a href="mailto:webmaster@equineregalia.com">webmaster@equineregalia.com</a> with
      questions or comments about this web site.<br>
      Copyright &copy; 1967 - 2022 Equine Regalia</p>
  </div>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>
div.footer {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  color: #E2DCA4;
  width: 960px;
  height: 60px;
  border-style: solid;
  border-color: #E2DCA4;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
}

p.footer {
  margin-left: 15px;
  margin-top: 13px;
}
</style>