<template>
  <div>
    <div class="content">
      <header-component></header-component>

      <menu-component></menu-component>

      <div class="main_content">
        <div class="home_text_top">
          Let Us Design Your
        </div>
        <div class="home_img_main">

        </div>
        <div class="home_text_bottom">
          Champion Of The Ring
        </div>
      </div>

      <div class="clearfooter"></div>

      <footer-component></footer-component>

    </div>
  </div>
</template>

<script>
import headerComponent from "@/components/HeaderComponent";
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";

export default {
  name: "HomeView",
  components: {
    headerComponent,
    menuComponent,
    footerComponent
  }
}
</script>

<style scoped>

div.main_content {
  height: 590px;
}

div.clearfooter {
  height: 8px;
  clear: both;
}

div.home_img_main {
  position: relative;
  width: 575px;
  height: 430px;
  margin-top: 10px;
  margin-left: 100px;
  background-image: url('../assets/images/home_main_img.jpg');
}

div.home_text_top {
  position: relative;
  width: 575px;
  margin-left: 250px;
  margin-top: 10px;
  font-size: 32px;
  color: #E2DCA4;
}

div.home_text_bottom {
  position: relative;
  margin-top: 10px;
  margin-left: 240px;
  width: 575px;
  font-size: 32px;
  color: #E2DCA4;
}
</style>