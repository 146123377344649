<template>
  <div>
    <div class="content">
      <header-component></header-component>

      <menu-component></menu-component>

      <div class="main_content">
        <div class="costumes_header">
          Costumes
        </div>
        <div class="costumes_for_rider_header">
          For The Rider
        </div>

        <div class="costumes_for_rider_image">
          <img src="/images/costumes/rider1.JPG"/>
          <img src="/images/costumes/rider2.jpg" height="147"/>
        </div>

        <div class="costumes_for_rider_text">
          <ul>
            <li>Cape or Aba</li>
            <li>Headdress</li>
            <li>Shirt</li>
            <li>Pants</li>
            <li>Optional: Cumberbund</li>
          </ul>
        </div>

        <div class="costumes_for_horse_header">
          For The Horse
        </div>

        <div class="costumes_for_horse_image">
          <img src="/images/costumes/horse1.jpg" height="147"/>
        </div>

        <div class="costumes_for_horse_text">
          <ul>
            <li>Bridle (Adjustable @ Crown)</li>
            <li>2 Sets of Reins</li>
            <li>Caveson</li>
            <li>Breast Collar</li>
            <li>Saddle Blanket</li>
            <li>Croup Cover</li>
            <li>Optional: Poll Cover, Stirrup Cover</li>
          </ul>
        </div>

        <div class="costume_yousupply">
          You supply the snaffle hanger (if used), bits, saddle and footwear.
        </div>

      </div>

      <div class="clearfooter"></div>

      <footer-component></footer-component>

    </div>
  </div>
</template>

<script>
import headerComponent from "@/components/HeaderComponent";
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";

export default {
  name: "HomeView",
  components: {
    headerComponent,
    menuComponent,
    footerComponent
  }
}
</script>

<style scoped>

div.main_content {
  height: 590px;
}

div.clearfooter {
  height: 6px;
  clear: both;
}


div.costumes_header {
  position:relative;
  color:#E2DCA4;
  text-align:center;
  font-size:24px;
  padding-top:10px;
}

div.costumes_for_rider_header {
  position:relative;
  color:#E2DCA4;
  text-align:center;
  font-size:19px;
  width:109px;
  top:0px;
  left:130px;
}

div.costumes_for_rider_image {
  position:relative;
  width:254px;
  height:147;
  top:5px;
  left:70px;
}

div.costumes_for_rider_text {
  position:relative;
  color:#E2DCA4;
  top:0px;
  left:70px;
}

div.costumes_for_horse_header {
  position:relative;
  color:#E2DCA4;
  text-align:center;
  font-size:19px;
  width:112px;
  top:-292px;
  left:506px;
}

div.costumes_for_horse_image {
  position:relative;
  width:275px;
  top:-292px;
  left:500px;
}

div.costumes_for_horse_text {
  position:relative;
  color:#E2DCA4;
  width:275px;
  top:-292px;
  left:435px;
}

div.costume_yousupply {
  position:relative;
  top:-275px;
  color:#E2DCA4;
  text-align:center;
}
</style>