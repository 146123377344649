<template>
  <div class="header">
    &nbsp;
    <div class="header_contact_info">
      <p>
        Equine Regalia<br>
        Jim Kellogg<br>
        3313 Highland Ave.<br>
        Benton Harbor, MI 49022<br>
        (269) 325-7901<br>
        <a href="mailto:akkhir@parrett.net">akkhir@parrett.net</a>
      </p>
    </div>
    <div class="header_img_right">
      &nbsp;
    </div>
    <div class="header_title">
      <p>
        EQUINE REGALIA
      </p>
    </div>
    <div class="header_img_left">
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style scoped>
div.header {
  position: relative;
  border-style: solid;
  border-color:#E2DCA4;
  width:960px;
  height:145px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
}

div.header_img_right {
  position: relative;
  background-image: url('../assets/images/header_img_nose_right.JPG');
  width:90px;
  height:106px;
  margin-left:270px;
  margin-top:-140px;
  margin-top:-140px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
}

div.header_img_left {
  position: relative;
  background-image: url('../assets/images/header_img_nose_left.JPG');
  width:90px;
  height:106px;
  margin-left:820px;
  margin-top:-170px;
  margin-top:-170px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 15px;
}

div.header_title {
  position:relative;
  color:#E2DCA4;
  width:385px;
  height:143px;
  font-size:45px;
  font-style:italic;
  font-weight:bold;
  margin-left:400px;
  margin-top:-125px;
  margin-top:-125px;
}

div.header_contact_info {
  position:relative;
  color:#E2DCA4;
  width:170px;
  height:143px;
  margin-top:-18px;
  margin-left:15px;
  margin-top:-18px;
}
</style>