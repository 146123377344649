<template>
  <div>
    <div class="content">
      <header-component></header-component>

      <menu-component></menu-component>

      <div class="main_content">
        <div class="winning_compinations_header">
          A Few Winning Combinations
        </div>

        <hr>

        <div class="winning_compinations_main">
          <ul class="hoverbox">
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/001.jpg" alt="description" /><img src="images/winning_combo/001.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/002.jpg" alt="description" /><img src="images/winning_combo/002.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/003.jpg" alt="description" /><img src="images/winning_combo/003.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/004.jpg" alt="description" /><img src="images/winning_combo/004.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/005.jpg" alt="description" /><img src="images/winning_combo/005.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/006.jpg" alt="description" /><img src="images/winning_combo/006.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/007.jpg" alt="description" /><img src="images/winning_combo/007.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/008.jpg" alt="description" /><img src="images/winning_combo/008.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/009.jpg" alt="description" /><img src="images/winning_combo/009.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/010.jpg" alt="description" /><img src="images/winning_combo/010.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/011.jpg" alt="description" /><img src="images/winning_combo/011.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/012.jpg" alt="description" /><img src="images/winning_combo/012.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/013.jpg" alt="description" /><img src="images/winning_combo/013.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/014.jpg" alt="description" /><img src="images/winning_combo/014.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/015.jpg" alt="description" /><img src="images/winning_combo/015.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/016.jpg" alt="description" /><img src="images/winning_combo/016.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/017.jpg" alt="description" /><img src="images/winning_combo/017.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/018.jpg" alt="description" /><img src="images/winning_combo/018.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/019.jpg" alt="description" /><img src="images/winning_combo/019.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/020.jpg" alt="description" /><img src="images/winning_combo/020.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/021.jpg" alt="description" /><img src="images/winning_combo/021.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/022.jpg" alt="description" /><img src="images/winning_combo/022.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/023.jpg" alt="description" /><img src="images/winning_combo/023.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/024.jpg" alt="description" /><img src="images/winning_combo/024.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/025.jpg" alt="description" /><img src="images/winning_combo/025.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/026.jpg" alt="description" /><img src="images/winning_combo/026.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/027.jpg" alt="description" /><img src="images/winning_combo/027.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/028.jpg" alt="description" /><img src="images/winning_combo/028.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/029.jpg" alt="description" /><img src="images/winning_combo/029.jpg" alt="description" class="preview" /></a>
            </li>
            <li>
              <a class="hoverbox" href="#"><img src="images/winning_combo/030.jpg" alt="description" /><img src="images/winning_combo/030.jpg" alt="description" class="preview" /></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="clearfooter"></div>

      <footer-component></footer-component>

    </div>
  </div>
</template>

<script>
import headerComponent from "@/components/HeaderComponent";
import menuComponent from "@/components/MenuComponent";
import footerComponent from "@/components/FooterComponent";

export default {
  name: "HomeView",
  components: {
    headerComponent,
    menuComponent,
    footerComponent
  }
}
</script>

<style scoped>
div.clearfooter {
  height: 211px;
  clear: both;
}

div.clearfooter {
  height: 6px;
  height: 6px;
  clear: both;
}

div.winning_compinations_header {
  position:relative;
  color:#E2DCA4;
  text-align:center;
  font-size:24px;
  padding-top:10px;
}

div.winning_compinations_main {
  position:relative;
  color:#E2DCA4;
  text-align:center;
  font-size:24px;
  left:34px;
  height:600px;
}

/* =Hoverbox Code
----------------------------------------------------------------------*/

.hoverbox
{
  cursor: default;
  list-style: none;
}

.hoverbox a
{
  cursor: default;
}

.hoverbox a .preview
{
  display: none;
}

.hoverbox a:hover .preview
{
  display: block;
  position: absolute;
  top: -33px;
  left: -45px;
  z-index: 1;
}

.hoverbox img
{
  background: #fff;
  border-color: #aaa #ccc #ddd #bbb;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  padding: 2px;
  vertical-align: top;
  width: 100px;
  height: 75px;
}

.hoverbox li
{
  background: #eee;
  border-color: #ddd #bbb #aaa #ccc;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  display: inline;
  float: left;
  margin: 3px;
  padding: 5px;
  position: relative;
}

.hoverbox .preview
{
  border-color: #000;
  width: 200px;
  height: 150px;
}
</style>